import { GridCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DetailCTARenderer } from '../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { getDate, usaCurrency } from '../../../utils/helpers';
import { format } from 'date-fns';
import { allOperators, booleanOperators, standardOperators } from '../../Compounds/UpstackDataGrid/helpers';
import { UpstackSourcedRenderer } from '../../Compounds/UpstackSourcedRenderer';
import { getAccountName } from '../../../store/Accounts';

export const DefaultInventoryGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: 'Details',
    sortable: false,
    width: 65,
    filterable: false,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'UPSTACK Managed',
    headerName: 'UPSTACK Managed',
    sortable: true,
    renderCell: (params: GridCellParams) => <UpstackSourcedRenderer upstackManaged={!!params.value} />,
    width: 125,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: booleanOperators(),
    disableReorder: true,
    disableColumnMenu: true
  },
  {
    field: 'account_id',
    headerName: 'Account',
    sortable: true,
    width: 300,
    type: 'singleSelect',
    valueOptions: [],
    valueFormatter: (value) => {
      return getAccountName(value);
    },
    valueGetter: (value, row) => getAccountName(row?.account_id),
    disableReorder: true,
    filterable: true,
    filterOperators: standardOperators()
  },
  {
    field: 'sfId',
    headerName: 'Id',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    valueGetter: (value, row) => (row?.['UPSTACK Managed'] ? row?.sfId : row?.id),
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'addressInfo.number',
    headerName: 'Location Code',
    sortable: true,
    width: 200,
    filterable: true,
    valueGetter: (value, row) => row?.addressInfo?.number,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: true,
    width: 300,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'productDisplay',
    headerName: 'Product',
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'supplier.name',
    headerName: 'Supplier',
    valueGetter: (value, row) => row?.supplier?.name,
    sortable: true,
    width: 112,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'accessProvider',
    headerName: 'Access Provider',
    sortable: true,
    width: 140,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'mRC',
    headerName: 'Monthly Cost',
    sortable: true,
    valueFormatter: (value) => {
      return `${usaCurrency.format(value)}`;
    },
    width: 125,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'nRC',
    headerName: 'One-Time Cost',
    sortable: true,
    valueFormatter: (value) => {
      return `${usaCurrency.format(value)}`;
    },
    width: 125,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'term',
    headerName: 'Term Length',
    sortable: true,
    valueFormatter: (value: string) => {
      return !value || value.includes('Month') ? value : `${value} Months`;
    },
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'completionDate',
    headerName: 'Install Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 190,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'serviceActivationDate',
    headerName: 'Activation Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 190,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'contractEndDate',
    headerName: 'Est. Contract End Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'providerAccountNumber',
    headerName: 'Account Number',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'supplierOrderNumber',
    headerName: 'Supplier Order #',
    sortable: true,
    width: 160,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'disconnectedReason',
    headerName: 'Disconnected Reason',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'bandwidth',
    headerName: 'Bandwidth',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'portSpeed',
    headerName: 'Port Speed',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'equipmentDetails',
    headerName: 'Description',
    sortable: true,
    width: 170,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'circuitIDs',
    headerName: 'Circuit ID',
    sortable: true,
    width: 350,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'lecCircuitID',
    headerName: 'Local Circuit ID',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'iPDetails',
    headerName: 'IP Addresses',
    sortable: true,
    width: 600,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'rackType',
    headerName: 'Rack Type',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'kwRack',
    headerName: 'kW Rack',
    sortable: true,
    width: 135,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationALecCircuitId',
    headerName: 'Location A Circuit ID',
    sortable: true,
    width: 260,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationALecDemarc',
    headerName: 'Location A Demarc',
    sortable: true,
    width: 250,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationALocalLec',
    headerName: 'Location A Local LEC',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationAText',
    headerName: 'Location A',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZLecCircuitId',
    headerName: 'Location Z Circuit ID',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZLecDemarc',
    headerName: 'Location Z Demarc',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZLocalLec',
    headerName: 'Location Z Local LEC',
    sortable: true,
    width: 120,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZText',
    headerName: 'Location Z',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'equipmentManufacturer',
    headerName: 'Equipment Manufacturer',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'disconnectedDate',
    headerName: 'Disconnected Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'lecDemarc',
    headerName: 'Demarcation Details',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'line_item_count',
    headerName: 'Line Items',
    sortable: true,
    width: 160,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];

export const SupportNumberDefaults: GridSingleSelectColDef[] = [
  {
    field: 'productSupportNumber',
    headerName: 'Supplier Tech Support',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'supplierSupportNumber',
    headerName: 'Supplier General Support',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];

export const NewRoute1FieldsDefaults: GridSingleSelectColDef[] = [
  {
    field: 'enclosure',
    headerName: 'Enclosure',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'floor',
    headerName: 'Floor',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'focDate',
    headerName: 'FOC Date',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'licenseQuantity',
    headerName: 'License Qty.',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'licenseExpirationDate',
    headerName: 'License End Date',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'metered',
    headerName: 'Metered Power',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'model',
    headerName: 'Model',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'mount',
    headerName: 'Install Mount',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'networkType',
    headerName: 'Network Type',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'pduPhase',
    headerName: 'PDU Phase',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'powerType',
    headerName: 'Power Type',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'downloadSpeedMbps',
    headerName: 'Download Speed',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'uploadSpeedMbps',
    headerName: 'Upload Speed',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'Voice Type',
    headerName: 'Supplier Tech Support',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];
